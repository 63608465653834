<template>
  <v-row justify="end" align="end">
    <slot name="antesAcoes"></slot>
    <slot name="antesAcoes2"></slot>
    <acao-fluxo
      v-if="mostrarJustificativa"
      ref="acaoMostrarJustificativa"
      icone="info"
      :mostrarRodape="false"
      :mostrar-campos-dinamicos="mostrarCamposDinamicos"
      @click.native="preencheInformacoesJustificativa"
      :texto-acao="$tc('label.justificativa', 1)">
    </acao-fluxo>

    <v-tooltip left v-if="mostrarAcompanhamento">
      <template v-slot:activator="{ on }">
        <v-btn text icon
              v-on="on"
              @click.native="abrirAcompanhamento">
          <v-icon >assignment_turned_in</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.acompanhamento') }}</span>
    </v-tooltip>

    <div v-if="apresentaJustificativaAoSolicitar">
      <acao-fluxo
        v-if="podeSolicitarAprovacao"
        ref="acaoSolicitarAprovacao"
        icone="send"
        :texto-acao="$t('label.solicitar_aprovacao')"
        :status="status"
        @AcaoFluxo_executarAcao="iniciaFluxo"
        :perguntar-proximo="perguntarProximo">
      </acao-fluxo>
    </div>
    <div v-else>
      <popover-confirmacao
        v-if="podeSolicitarAprovacao"
        :message="$tc('message.deseja_solicitar_aprovacao_apuracao', 1)"
        @agree="iniciaFluxo"
        icone="send"
        :textoAcao="$t('label.solicitar_aprovacao')">
      </popover-confirmacao>
    </div>

    <popover-confirmacao
      v-if="podeConcluirApuracao"
      :message="$tc('message.deseja_concluir_apuracao_simples', 1)"
      @agree="concluirApuracao"
      icone="done"
      :textoAcao="$t('label.concluir_apuracao')">
    </popover-confirmacao>

    <acao-fluxo
      v-if="podeCancelarApuracao"
      ref="acaoCancelar"
      icone="cancel"
      :mostrarRodape="true"
      :validarJustificativa="true"
      @AcaoFluxo_executarAcao="cancelarApuracao"
      :texto-acao="isStatusAguardandoAprovacao ? `${$tc('label.cancelar', 1)} ${$tc('title.fluxo_aprovacao')}` : $tc('message.deseja_cancelar_apuracao', 1)">
    </acao-fluxo>

    <acao-fluxo
      ref="acaoAprovar"
      icone="thumb_up"
      :id-acao="idAcao"
      :texto-acao="$t('label.aprovar')"
      :acao="acao"
      :status="status"
      :mostrar-campos-dinamicos="mostrarCamposDinamicos"
      :configuracao="config"
      :perguntar-proximo="perguntarProximo"
      v-if="exibeAprovar"
      @AcaoFluxo_executarAcao="aprovar">
    </acao-fluxo>

    <acao-fluxo
      ref="acaoEmAnalise"
      icone="announcement"
      :texto-acao="$t('label.solicitar_analise')"
      :status="status"
      :perguntar-proximo="perguntarProximo"
      :validar-justificativa="true"
      v-if="exibeAprovar"
      @AcaoFluxo_executarAcao="enviarAnalise">
    </acao-fluxo>

    <acao-fluxo
      ref="acaoReprovar"
      icone="thumb_down"
      :texto-acao="$t('label.reprovar')"
      :status="status"
      :perguntar-proximo="perguntarProximo"
      :validar-justificativa="true"
      v-if="exibeReprovar"
      @AcaoFluxo_executarAcao="reprovar">
    </acao-fluxo>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"/>

    <apuracao-acao-detalhes-apuracao-rateio
      ref="modalRateio"
      :id-apuracao="getIdApuracao()"
      :vlr-conf-apuracao="getVlrConfApuracao()"
      :vlr-reservado="getVlrReservado()"
      :tipos-verbas="getTiposVerbas()"
      :periodos-orcamentarios="getPeriodosOrcamentarios()"
      :somente-leitura="getImpedirEdicaoValor()"
      />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';
import PopoverConfirmacao from '../../shared-components/PopoverConfirmacao';
import ApuracaoAcaoDetalhesApuracaoRateio from '../apuracao-acao/ApuracaoAcaoDetalhesApuracaoRateio';
import { getMoney } from '../../common/functions/helpers';

export default {
  name: 'ApuracaoAcaoFluxoContrato',
  props: {
    idAcao: Number,
    idApuracao: Number,
    status: String,
    vlrConfApuracao: Number,
    vlrConfApuracaoOriginal: Number,
    valorRealizado: Number,
    vlrReservado: Number,
    idContrato: Number,
    acao: {
      type: Object,
      default: {},
    },
    exibirAcompanhamento: {
      type: Boolean,
      default: true,
    },
    exibirSolicitarAprovacao: {
      type: Boolean,
      default: true,
    },
    exibirConcluirAprovacao: {
      type: Boolean,
      default: true,
    },
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    salvarAntesSolicitarAprovacao: {
      type: Boolean,
      default: false,
    },
    indJustificativaAnalise: {
      type: Boolean,
      default: false,
    },
    indHabilitaFluxo: {
      type: Boolean,
      default: false,
    },
    indHabilitaEdicaoAprovacao: {
      type: Boolean,
      default: false,
    },
    observacaoAcao: {
      type: String,
      default: '',
    },
    qtdContasVinculadas: Number,
    tiposVerbas: String,
    periodosOrcamentarios: String,
    podeCancelar: Boolean,
    podeReprovar: Boolean,
    impedirEdicaoValor: Boolean,
    divisaoUsuario: Boolean,
    indHabilitarJustificativaAlterarValor: Boolean,
    justificativaAlteracaoValorPagamento: {
      type: String,
      default: '',
    },
    valorAtualApuracao: {
      type: Number,
      default: null,
    },
  },
  components: {
    PopoverConfirmacao,
    AcaoFluxo,
    Confirm,
    Acompanhamento,
    ApuracaoAcaoDetalhesApuracaoRateio,
  },
  data() {
    return {
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      configuracaoContratoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      solicitanteFluxo: false,
      aprovadorFluxo: false,
      usuarioConcluiApuracaoSemFluxo: false,
      statusPassos: {},
      config: {},
    };
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeSolicitarAprovacao() {
      return (this.canEdit || this.canAccessCRUD) && (this.getStatusApuracao() === 'AGUARDANDO_APURACAO' || this.getStatusApuracao() === 'EM_ANALISE')
             && this.exibirSolicitarAprovacao && !this.exibirConcluirAprovacao;
    },
    podeCancelarApuracao() {
      return ((this.canEdit || this.canAccessCRUD) && this.solicitanteFluxo && this.getPodeCancelar());
    },
    podeResgatarApuracao() {
      return this.isStatusVencido && !!this.getAllRoles
        .filter((el) => el === 'APU_RESGATA_VENCIDO').length;
    },
    podeConcluirApuracao() {
      return (this.canEdit || this.canAccessCRUD) && this.isStatusAguardandoApuracao && this.exibirConcluirAprovacao
      && this.solicitanteFluxo && (this.getStatusApuracao() === 'AGUARDANDO_APURACAO' || this.getStatusApuracao() === 'EM_ANALISE');
    },
    exibeAprovar() {
      return this.getStatusApuracao() === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    exibeReprovar() {
      return this.exibeAprovar && this.getPodeReprovar();
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && !this.isStatusAguardandoApuracao
        && !this.isStatusApuracaoPrevia && !this.isStatusCancelado
        && !this.isStatusVencido && this.indHabilitaFluxo;
    },
    isStatusAguardandoAprovacao() {
      return this.getStatusApuracao() === 'AGUARDANDO_APROVACAO';
    },
    isStatusAguardandoApuracao() {
      return this.getStatusApuracao() === 'AGUARDANDO_APURACAO';
    },
    isStatusApuracaoPrevia() {
      return this.getStatusApuracao() === 'APURACAO_PREVIA';
    },
    isStatusEmAnalise() {
      return this.getStatusApuracao() === 'EM_ANALISE';
    },
    isStatusVencido() {
      return this.getStatusApuracao() === 'VENCIDO';
    },
    isStatusCancelado() {
      return this.getStatusApuracao() === 'CANCELADO';
    },
    apresentaJustificativaAoSolicitar() {
      return this.indHabilitaFluxo && this.indJustificativaAnalise
        && this.isStatusEmAnalise;
    },
    mostrarJustificativa() {
      return this.indHabilitaFluxo && this.indJustificativaAnalise
        && this.isStatusAguardandoAprovacao && this.observacaoAcao;
    },
    possuiVinculoMultiplasContas() {
      return (this.qtdContasVinculadas
        ? this.qtdContasVinculadas : this.acao.qtdContasVinculadas) > 1;
    },
    mostrarCamposDinamicos() {
      return this.indHabilitaFluxo && this.indHabilitaEdicaoAprovacao
        && this.isStatusAguardandoAprovacao;
    },
    possuiVinculoUnicaConta() {
      return (this.qtdContasVinculadas
        ? this.qtdContasVinculadas : this.acao.qtdContasVinculadas) === 1;
    },
    apuracaoSemContaCorrente() {
      return (this.qtdContasVinculadas
        ? this.qtdContasVinculadas : this.acao.qtdContasVinculadas) === 0;
    },
    indControleVerbaApenasPlanejamento() {
      if (!this.config || !this.config.passo1) {
        return false;
      }
      return this.config.passo1.indControleVerbaApenasPlanejamento;
    },
  },
  methods: {
    preencheInformacoesJustificativa() {
      if (!this.observacaoAcao) {
        return;
      }
      this.$refs.acaoMostrarJustificativa
        .preencheJustificativa(this.observacaoAcao);
    },
    possuiAcessoCliente() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CLIENTE_APU_ACAO_CRUD').length;
    },
    carregaInformacoesFluxo() {
      if (this.getIdApuracao() && this.getStatusApuracao()) {
        this.verificarUsuarioSolicitante(this.getIdApuracao());
        this.verificarUsuarioAprovador(this.getIdApuracao());
        if (!this.indHabilitaFluxo) {
          this.verificarUsuarioConcluiApuracaoSemFluxo(this.getIdApuracao());
        }
      }
    },
    verificarUsuarioSolicitante(idApuracao) {
      if (this.isStatusAguardandoApuracao
          || this.isStatusEmAnalise
          || this.isStatusAguardandoAprovacao
          || this.isStatusApuracaoPrevia
          || this.isStatusVencido) {
        this.workflowApuracaoAcaoResource.solicitante({ idApuracao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    verificarUsuarioConcluiApuracaoSemFluxo() {
      // if (this.isStatusAguardandoApuracao || this.isStatusApuracaoPrevia) {
      //   this.apuracaoAcaoListagemResource.usuarioConcluiApuracaoSemFluxo({ idApuracao })
      //     .then((res) => {
      //       if (res.data) {
      //         this.usuarioConcluiApuracaoSemFluxo = true;
      //       } else {
      //         this.usuarioConcluiApuracaoSemFluxo = false;
      //       }
      //       this.$forceUpdate();
      //     });
      // }
    },
    iniciaFluxo(observacao = null) {
      if (this.getValorRealizado() === 0) {
        this.$toast(this.$t('message.valor_realizado_zerado'));
        return;
      }
      const idApuracao = this.getIdApuracao();
      if (!this.salvarAntesSolicitarAprovacao && this.apuracaoSemContaCorrente) {
        this.solicitaAprovacao(idApuracao, observacao);
        return;
      }

      if (this.indHabilitarJustificativaAlterarValor
        && ((this.getVlrConfApuracao() !== this.getVlrConfApuracaoOriginal()
        || this.getVlrConfApuracao() !== this.valorAtualApuracao)
        && this.justificativaAlteracaoValorPagamento === '')) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
        return;
      }

      this.salvarApuracao(idApuracao)
        .then((impedidoSolicitar) => {
          if (impedidoSolicitar) return;
          this.solicitaAprovacao(idApuracao, observacao);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarApuracao(idApuracao) {
      const parametros = {};
      parametros.valorRealizado = this.getValorRealizado();
      parametros.vlrConfApuracao = this.getVlrConfApuracao();
      parametros.justificativaAlteracaoValorPagamento = this.justificativaAlteracaoValorPagamento;
      parametros.valorRealizadoOriginal = this.valorAtualApuracao;
      parametros.id = idApuracao;

      if (this.apuracaoSemContaCorrente) {
        return this.apuracaoAcaoResource.atualizar(parametros).then(() => false);
      }

      if (this.possuiVinculoMultiplasContas && !this.indControleVerbaApenasPlanejamento) {
        return new Promise((resolve, reject) => {
          this.$refs.modalRateio.exibirModal((valoresPendentes) => {
            parametros.valoresPendentes = valoresPendentes;
            this.apuracaoAcaoResource.atualizar(parametros)
              .then(() => resolve(false))
              .catch(reject);
          });
        });
      }

      return this.validaSaldoContaUnica()
        .then((saldoSuficiente) => new Promise((resolve, reject) => {
          if (saldoSuficiente) {
            this.apuracaoAcaoResource.atualizar(parametros)
              .then(() => resolve(false))
              .catch(reject);
            return;
          }
          const impedidoSolicitar = true;
          resolve(impedidoSolicitar);
        }));
    },
    validaSaldoContaUnica() {
      const idApuracao = this.getIdApuracao();
      const vlrConfApuracao = this.getVlrConfApuracao();

      return this.apuracaoAcaoResource
        .validaSaldoContaUnica({ idApuracao, valorAlterado: vlrConfApuracao })
        .then((res) => {
          const validacao = res.data;
          const { suficiente } = validacao;

          if (!suficiente) {
            this.$emit('ApuracaoFluxo__AusenciaSaldo', {
              periodo: validacao.periodo,
              saldo: getMoney(validacao.saldo),
            });
          }

          return suficiente;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    solicitaAprovacao(idApuracao, observacao = '') {
      if (!this.justificativaAlteracaoValorPagamento) {
        this.$toast(this.$t('message.justificativa_necessaria'));
        return;
      }
      this.workflowApuracaoAcaoResource.iniciaFluxo({ idApuracao }, { idApuracao, observacao })
        .then(() => {
          this.$toast(this.$t('message.solicitacao_aprovacao'));
          this.$emit('recarregar', 'AGUARDANDO_APROVACAO');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador(idApuracao) {
      if (this.isStatusAguardandoAprovacao) {
        this.workflowApuracaoAcaoResource.aprovador({ idApuracao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            } else {
              this.aprovadorFluxo = false;
            }
            this.$forceUpdate();
          });
      }
    },
    reprovar(justificativa, irProximo) {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource.reprovar({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar', 'REPROVADO');
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.apuracao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa, irProximo, valoresMetadados) {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource.aprovarPasso({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar', 'APROVADO');
          this.verificarUsuarioAprovador(this.getIdApuracao(), this.getStatusApuracao());
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.apuracao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
      if (valoresMetadados && this.acao) {
        const payload = { id: this.acao.idAcao, desAtributos: valoresMetadados };
        this.apuracaoAcaoResource.atualizarDesAtributos(payload)
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    enviarAnalise(justificativa, irProximo) {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource.enviarAnalise({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar', 'EM_ANALISE');
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.apuracao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    cancelarApuracao(observacao) {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource.cancelar({ idApuracao }, { observacao })
        .then(() => {
          if (this.isStatusAguardandoAprovacao) {
            this.$emit('recarregar', 'AGUARDANDO_APURACAO');
            this.$toast(this.$t('message.apuracao_fluxo_cancelado'));
          } else {
            this.$emit('recarregar', 'CANCELADO');
            this.$toast(this.$t('message.apuracao_cancelado'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    resgatarApuracao(justificativa, irProximo, valoresMetadados, dtaProrrogacao) {
      const idApuracao = this.getIdApuracao();
      this.apuracaoAcaoResource.resgatar({ idApuracao }, { justificativa, dtaProrrogacao })
        .then(() => {
          this.$emit('recarregar', 'AGUARDANDO_APURACAO');
          this.$toast(this.$t('message.apuracao_resgatada'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    concluirApuracao() {
      const idApuracao = this.getIdApuracao();
      this.apuracaoAcaoResource.concluirApuracao({ idApuracao }, { idApuracao })
        .then(() => {
          this.$emit('recarregar', 'APROVADO');
          this.$toast(this.$t('message.apuracao_concluida'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    irProximaPagina(irProximo) {
      if (irProximo) {
        this.$emit('ApuracaoAcaoFluxo_irProximo');
      }
    },
    abrirAcompanhamento() {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource
        .existeFluxoCadastrado({ idApuracao })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.apuracao_finalizada_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idApuracao);
        });
    },
    exibeModalAcompanhamento(idApuracao) {
      this.workflowApuracaoAcaoResource.statusPasso({ idApuracao }, { idApuracao })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    clientePodeCancelar() {
      return this.possuiAcessoCliente() && this.getStatusApuracao() === 'AGUARDANDO_APROVACAO';
    },
    getTiposVerbas() {
      return this.tiposVerbas ? this.tiposVerbas : this.acao.tiposVerbas;
    },
    getPeriodosOrcamentarios() {
      return this.periodosOrcamentarios
        ? this.periodosOrcamentarios : this.acao.periodosOrcamentarios;
    },
    getIdApuracao() {
      return this.idApuracao ? this.idApuracao : this.acao.idApuracao;
    },
    getIdAcao() {
      return this.idAcao ? this.idAcao : this.acao.idAcao;
    },
    getStatusApuracao() {
      return this.status ? this.status : this.acao.statusApuracao;
    },
    getVlrConfApuracao() {
      if (this.vlrConfApuracao) {
        return this.vlrConfApuracao;
      }
      if (this.acao && this.acao.vlrConfApuracao) {
        return this.acao.vlrConfApuracao;
      }
      return 0;
    },
    getVlrConfApuracaoOriginal() {
      if (this.vlrConfApuracaoOriginal) {
        return this.vlrConfApuracaoOriginal;
      }
      if (this.acao && this.acao.vlrConfApuracaoOriginal) {
        return this.acao.vlrConfApuracaoOriginal;
      }
      return 0;
    },
    getVlrReservado() {
      if (this.vlrReservado) {
        return this.vlrReservado;
      }
      if (this.acao && this.acao.vlrReservado) {
        return this.acao.vlrReservado;
      }
      return 0;
    },
    getValorRealizado() {
      if (this.valorRealizado) {
        return this.valorRealizado;
      }
      if (this.acao && this.acao.valorRealizado) {
        return this.acao.valorRealizado;
      }
      return 0;
    },
    getPodeCancelar() {
      if (this.podeCancelar) {
        return this.podeCancelar;
      }
      if (this.acao && this.acao.podeCancelar) {
        return this.acao.podeCancelar;
      }
      return false;
    },
    getPodeReprovar() {
      if (this.podeReprovar) {
        return this.podeReprovar;
      }
      if (this.acao && this.acao.podeReprovar) {
        return this.acao.podeReprovar;
      }
      return false;
    },
    getImpedirEdicaoValor() {
      if (this.impedirEdicaoValor) {
        return this.impedirEdicaoValor;
      }
      if (this.acao && this.acao.impedirEdicaoValor) {
        return this.acao.impedirEdicaoValor;
      }
      return false;
    },
    carregarConfiguracao() {
      const { idContrato } = this.acao || this.idContrato;
      return this.configuracaoContratoResource.buscarConfigSnapshot({ idContrato }).then((res) => {
        this.config = res.data;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  beforeMount() {
    this.carregaInformacoesFluxo();
  },
  mounted() {
    this.carregarConfiguracao();
  },
};
</script>
