<template>
  <div>
    <v-container fluid px-0 class="Container_Nivel2_Apuracao">
      <v-row align="center" justify="center" class="mx-0">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa"
            single-line
            hide-details
            clearable>
          </v-text-field>
        </v-col>

        <v-container fluid grid-list-md v-if="lista.length">
          <v-data-iterator
            :items="lista"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start">
                <v-col
                  v-for="item in props.items"
                  :key="item.cod_apuracao"
                  cols="12"
                  sm="4"
                  md="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`Card_${item.status} elevation-${hover ? 5 : 1}`"
                            fill-height class="clickable Card_Apuracao">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-card-title class="Card_Title" v-on="on" @click="selectItem(item)">
                            <v-row justify="center" align="center">
                              <p style="text-align: center;">
                               {{ item.codContrato }} - {{ item.codAcao }} - {{ item.codApuracao }}
                                <span v-if="isVisaoStatus && isOrigemOuFocoExtensao(item)">
                                  <br />{{ (item.focoContrato) | truncate(25) }}
                                </span>
                                <span v-if="isVisaoStatus && !isOrigemOuFocoExtensao(item)">
                                  <br />{{ item.titulo | truncate(25) }}
                                </span>
                                <span v-if="!isVisaoStatus">
                                  <br />{{ item.descricao | truncate(25) }}
                                </span>
                              </p>
                            </v-row>
                          </v-card-title>
                        </template>
                        <div>
                          {{ $t('label.descricao') }}: {{ item.descricao }}<br />
                          {{ $t('label.status') }}: {{$tc(`status_entidade.${item.status.toLowerCase()}`, 1)}}
                        </div>
                      </v-tooltip>
                      <v-card-text class="Card_Content py-0" @click="selectItem(item)">
                        <v-row justify="center" align="center">
                          <p nowrap class="headline font-weight-light">
                            {{ getMoney(item.pagamento) }}
                          </p>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="Card_Actions">
                        <apuracao-acao-fluxo-contrato
                          class="pa-3"
                          :observacaoAcao="item.observacao_acao"
                          :indJustificativaAnalise="item.ind_justificativa_analise"
                          :indHabilitaFluxo="item.ind_habilita_fluxo"
                          :indHabilitaEdicaoAprovacao="item.ind_habilita_edicao_aprovacao"
                          :justificativaAlteracaoValorPagamento="item.justificativa"
                          :acao="item.acao"
                          :idAcao="item.cod_acao"
                          :idApuracao="item.cod_apuracao"
                          :status="item.status"
                          :vlr-conf-apuracao="item.vlrConfApuracao"
                          :vlr-conf-apuracao-original="item.vlrConfApuracaoOriginal"
                          :vlr-reservado="item.vlr_reservado"
                          :valor-realizado="item.valor_realizado"
                          :qtd-contas-vinculadas="item.qtd_contas_vinculadas"
                          :pode-cancelar="item.podeCancelar"
                          :pode-reprovar="item.podeReprovar"
                          :is-fluxo-paralelo="item.is_fluxo_paralelo"
                          :exibir-solicitar-aprovacao="true"
                          :exibir-concluir-aprovacao="item.podeConcluir"
                          :tipos-verbas="item.tipos_verbas"
                          :divisaoUsuario="item.idDivisao ? !!divisao.includes(item.idDivisao.toString()) : false"
                          :periodos-orcamentarios="item.periodos_orcamentarios"
                          @recarregar="buscar()"
                          @ApuracaoFluxo__AusenciaSaldo="exibirMensagemSaldo">
                          <v-col slot="antesAcoes" class="pa-0">
                            {{ $t('label.tipo_acao') }}: {{item.tipo}}
                          </v-col>
                        </apuracao-acao-fluxo-contrato>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
    </v-container>
    <v-dialog
      v-model="exibeMensagemSaldo"
      max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t('title.atencao') }}</v-card-title>
        <v-card-text>
          {{ this.$t('message.saldo_insuficiente_conta', dadosValidacaoSaldo) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
            text
            @click="() => exibeMensagemSaldo = false">{{ $t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApuracaoAcaoFluxoContrato from './ApuracaoAcaoFluxoContrato';
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import {
  listarApuracoesContratoNivel2,
  listarApuracoesContratoNivel2Simplificado,
} from '../../common/resources/apuracao/apuracao-contrato';

export default {
  name: 'ApuracaoAcaoListNivel2',
  components: {
    ApuracaoAcaoFluxoContrato,
  },
  data() {
    return {
      pesquisaCards: '',
      status: null,
      idFornecedor: null,
      idFornecedorPagador: null,
      idGrupoFornecedor: null,
      idCliente: null,
      idHolding: null,
      idExterno: null,
      idExtensao: null,
      idRegional: null,
      mnemonico: null,
      origem: null,
      isContrato: null,
      acao: {},
      iteratorKey: 0,
      totalPage: 0,
      lista: [],
      filtrosAplicados: {},
      rowsPerPageItems: [12],
      pagination: {
        itemsPerPage: 12,
      },
      buscando: false,
      esperarDigitar: false,

      dadosValidacaoSaldo: null,
      exibeMensagemSaldo: false,
    };
  },
  watch: {
    filtroApuracaoContrato: {
      handler() {
        this.filtrosAplicados = this.filtroApuracaoContrato;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroApuracaoContrato) {
          this.buscar();
        }
      },
      deep: true,
    },
  },
  filters: {
    truncate(value, limit) {
      if (value && value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('filtros', [
      'filtroApuracaoContratoNivel2',
      'filtroApuracaoContrato',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isVisaoStatus() {
      return !!this.status;
    },

    isVisaoFornecedor() {
      return !!this.idFornecedor;
    },

    isVisaoFornecedorPagador() {
      return !!this.idFornecedorPagador;
    },

    isVisaoGrupoFornecedor() {
      return !!this.idGrupoFornecedor;
    },

    isVisaoCliente() {
      return !!this.idCliente;
    },

    isVisaoHolding() {
      return !!this.idHolding;
    },

    isVisaoRegional() {
      return !!this.idRegional;
    },

    isVisaoExtensao() {
      return !!this.mnemonico && !!this.idExterno;
    },
    isVisaoContrato() {
      return !!this.isContrato || this.$route.params.visao === 'Contrato';
    },
    divisao() {
      return this.usuarioLogado.divisao ? this.usuarioLogado.divisao.split(',') : [];
    },
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroApuracaoContratoNivel2',
    ]),
    exibirMensagemSaldo(dados) {
      this.dadosValidacaoSaldo = dados;
      this.exibeMensagemSaldo = true;
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    selectItem(item) {
      this.consultar(listarApuracoesContratoNivel2Simplificado, (response) => {
        const listaSimplificada = response.data;
        const params = {
          id: item.codApuracao,
          idAcao: item.codAcao,
          idContrato: item.codContrato,
          status: item.status,
          apuracoes: listaSimplificada,
          from: 'apuracao-contrato',
        };

        let query;

        if (this.isVisaoStatus) {
          params.status = this.status;
          this.$router.push({ name: 'detalharApuracaoContratoStatus', params, query });
        }

        if (this.isVisaoContrato) {
          params.origem = this.$route.params.origemContrato;
          this.$router.push({ name: 'detalharApuracaoContrato', params, query });
        }

        query = { origem: this.origem };

        if (this.isVisaoFornecedor) {
          params.idFornecedor = this.idFornecedor;
          this.$router.push({ name: 'detalharApuracaoAcaoFornecedor', params, query });
        }

        if (this.isVisaoFornecedorPagador) {
          params.idFornecedorPagador = this.idFornecedorPagador;
          this.$router.push({ name: 'detalharApuracaoAcaoFornecedorPagador', params, query });
        }

        if (this.isVisaoGrupoFornecedor) {
          params.idGrupoFornecedor = this.idGrupoFornecedor;
          this.$router.push({ name: 'detalharApuracaoAcaoGrupo', params, query });
        }

        if (this.isVisaoCliente) {
          params.idCliente = this.idCliente;
          this.$router.push({ name: 'detalharApuracaoAcaoCliente', params, query });
        }

        if (this.isVisaoHolding) {
          params.idHolding = this.idHolding;
          this.$router.push({ name: 'detalharApuracaoAcaoHolding', params, query });
        }

        if (this.isVisaoRegional) {
          params.idRegional = this.idRegional;
          this.$router.push({ name: 'detalharApuracaoAcaoRegional', params, query });
        }

        if (this.isVisaoExtensao) {
          params.mnemonico = this.mnemonico;
          params.idExterno = this.idExterno;
          this.$router.push({ name: 'detalharApuracaoAcaoExtensao', params, query });
        }
      });
    },
    buscar() {
      this.consultar(listarApuracoesContratoNivel2, (response) => {
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.lista = response.data.resposta;
        this.lista.forEach((i) => {
          i.acao = {};
          i.acao.idAcao = i.codAcao;
          i.acao.idApuracao = i.codApuracao;
          i.acao.idContrato = i.codContrato;
          i.acao.valorRealizado = i.realizado;
          const desAtributos = i.des_atributos
            ? JSON.parse(i.des_atributos.value) : {};
          i.acao = {
            ...i.acao,
            ...desAtributos,
          };
        });
        this.rowsPerPageItems = [12, 24, 36, 48, 60, 72, 84];
        setTimeout(this.finalizandoBusca);
        this.preencherFiltrosNivel1Vuex();
      });
    },
    consultar(consulta, sucesso) {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
        cod_contrato: this.$route.params.idContrato,
      };

      if (this.isVisaoFornecedor) {
        params.id_fornecedor = this.idFornecedor;
      } else if (this.isVisaoFornecedorPagador) {
        params.id_fornecedor_pagador = this.idFornecedorPagador;
      } else if (this.isVisaoGrupoFornecedor) {
        params.id_grupo_fornecedor = this.idGrupoFornecedor;
      } else if (this.isVisaoCliente) {
        params.id_cliente = this.idCliente;
      } else if (this.isVisaoHolding) {
        params.id_holding = this.idHolding;
      } else if (this.isVisaoStatus) {
        params.status = this.status;
      } else if (this.isVisaoRegional) {
        params.id_regional = this.idRegional;
      } else {
        params.id_externo = this.idExterno;
        params.mnemonico = this.mnemonico;
      }

      consulta(params, this.$resource)
        .then(sucesso)
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroApuracaoContratoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroApuracaoContratoNivel2.quantidatePorPagina;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroApuracaoContratoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
    divisaoUsuario() {
      if (this.acao && this.acao.idDivisao) {
        return !!this.divisao.includes(this.acao.idDivisao.toString());
      }
      return false;
    },
    finalizandoBusca() {
      this.buscando = false;
      this.$forceUpdate();
    },
    preencherFiltrosRouter() {
      this.status = this.$route.params.status;
      this.isContrato = this.$route.params.isContrato;
      this.idFornecedor = this.$route.params.idFornecedor;
      this.idFornecedorPagador = this.$route.params.idFornecedorPagador;
      this.idGrupoFornecedor = this.$route.params.idGrupoFornecedor;
      this.idCliente = this.$route.params.idCliente;
      this.idHolding = this.$route.params.idHolding;
      this.idExterno = this.$route.params.idExterno;
      this.idRegional = this.$route.params.idRegional;
      this.idExtensao = this.$route.params.idExtensao;
      this.mnemonico = this.$route.params.mnemonico;
      this.origem = this.$route.query.origem;
    },
    isOrigemOuFocoExtensao(item) {
      if ((this.indModoVarejo && !item.is_origem_fornecedor)
         || (!this.indModoVarejo && !item.is_foco_cliente && !item.is_foco_regional)) {
        return true;
      }
      return false;
    },
  },
  beforeMount() {
    this.preencherFiltrosRouter();
  },
  mounted() {
    if (this.filtroApuracaoContratoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroApuracaoContrato) {
      this.filtrosAplicados = this.filtroApuracaoContrato;
    }
    this.buscar();
  },
};
</script>

<style>
.Container_Nivel2_Apuracao .Card_Apuracao {
  min-height: 146px !important;
  height: 100%;
}
.Container_Nivel2_Apuracao .Card_Title, .Container_Nivel2_Apuracao .Card_Content {
  color: #757575;
}

.Barra_Pesquisa {
  width: 33%;
}
.clickable {
  cursor: pointer;
}
</style>
<style src="../../assets/css/card-status.css" lang="css"></style>
